// Color Variables

$base-padding: rem-calc(35);
$ecoblue: $primary-color;
$ecogreen: $secondary-color;

// General & Typography Fixes

body {
    text-align: center;
}

label {
    font-size: 18px;
    font-weight: $global-weight-bold;
}

.logo {
    padding: $base-padding;
}

header {
    background: $ecoblue;
}

h1,
.h1 {
    padding-top: $base-padding;
}

// Buttons

.btnTopMrg {
    margin-top: $base-padding;
}

a,
button,
.button {
    text-transform: uppercase;
    font-weight: $global-weight-bold;
}

// Thank you page

#countdown {
    color: red;
    font-weight: $global-weight-bold;
}


// Hubspot Form Fixes

.grecaptcha-badge {
    margin: 0 auto !important;
}

.hs-form-required {
    color: red;
}

ul.hs-error-msgs {
    list-style: none;

    li>label {
        font-size: 14px;
        font-weight: 400;
        color: red;
    }
}

ul.inputs-list {
    list-style: none;
}